function tarificationController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.tarifs = {};

    vm.get = function() {
        WS.get('tarifications/tarifs', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.tarifs = response.data;
                    $scope.$apply();
                    $('.table_tarifications').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(tarifs) {
        WS.put('tarifications/' + tarifs.id, tarifs)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Tarif modifié avec Succès.',
                        'success'
                    );
                } else {
                    swal(
                        'Oups!!',
                        '',
                        'warning'
                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.selectRoutes = function() {
        WS.get('routing/selectRoutes')
            .then(function(response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getSousFamilles = function() {
        WS.get('sousfamilles/select')
            .then(function(response) {
                vm.sousfamilles = response.data;
                

                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.delete = function(id) {
        swal({
            title: 'Tarification',
            text: "voulez vous vraiment supprimer cette Tarif!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('tarifications/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Tarif supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette Tarif!!',
                        'warning'
                    );
                }
            );
        });
    }


    vm.ajouter = function(tarif) {
        if (tarif.client_code == undefined || tarif.client_code == '') {
            swal(
                'Oups!',
                'Client obligatoire!',
                'warning'
            );
        } else if ( (tarif.produit_code == undefined || tarif.produit_code == '') && (tarif.sousfamilles == undefined || tarif.sousfamilles == '')) {
            swal(
                'Oups!',
                'Veuillez choisir un produit ou une sousfamille !',
                'warning'
            );
        } else if (tarif.date_debut == undefined || tarif.date_debut == '') {
            swal(
                'Oups!',
                'date obligatoire!',
                'warning'
            );
        } else {
            WS.post('tarifications', tarif)
                .then(function(response) {
                    if (response.data.status == true) {
                        swal(
                            'Succès',
                            'tarif ajoutée avec Succès',
                            'success'
                        ).then(function() {
                            $window.location.reload();
                        });

                    } else if (response.data.status == false) {
                        swal('Error', response.data.message, 'error');
                    }
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }

    };

    vm.selectProduits = function() {
        WS.get('produits/selectProduit')
            .then(function(response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    /*
    vm.selectClients = function() {
        WS.get('clients/selectInput')
            .then(function(response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    */

    vm.selectCategorieCommercial = function() {
        WS.get('categoriescommerciale/selectCategorieCommercial')
            .then(function(response) {
                if (response.status == 200) {
                    vm.categoriescommercials = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectClientByCategorie = function(categorie_commercial) {
        WS.get(`categoriescommerciale/selectClientByCategorie/${categorie_commercial}`)
            .then(function(response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.filterRegionByRoute = function (routings) {
        var region = (vm.region == "" || vm.region == undefined) ? "null" : vm.region;
        var zone = (vm.zone == "" || vm.zone == undefined) ? "null" : vm.zone;
        var secteur = (vm.secteur == "" || vm.secteur == undefined) ? "null" : vm.secteur;
        WS.get('routing/filterRegionByRoute/' + routings + '/' + region + '/' + zone + '/' + secteur)
            .then(function (response) {
                vm.clients = response.data.clients;
                
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


}
export default {
    name: 'tarificationController',
    fn: tarificationController
};