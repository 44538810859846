function authController($localStorage, $state, $scope, WS, $stateParams, $http, Flash) {
    'ngInject';
    const vm = this;

    vm.access = {}

    vm.login = function() {
        WS.post('login', vm.access)
            .then(function(response) {

                if (response.status == 200) {
                    $localStorage.user = response.data.user;
                    $localStorage.role = response.data.role;
                    $localStorage.dashboard = response.data.user.dashboard;
                    if ($localStorage.role.code == 'admin' || $localStorage.role.code == 'superviseur' || $localStorage.role.code == 'commercial') {
                        
                        if ($localStorage.dashboard == 'vente') {
                            $state.go('app.dashboard', {}, {
                                reload: false,
                                inherit: false
                            });
                            
                        }else{
                            $state.go('app.dashboardcommande', {}, {
                                reload: false,
                                inherit: false
                            });
                        }
                        
                        
                    } else if ($localStorage.role.code == 'magasinier') {

                        $state.go('app.users.index', {}, { reload: true });
                    }
                    else if ($localStorage.role.code == 'caissier') {
                        $state.go('app.chargements.stat-chargements', {},{ reload: true });

                    } else if($localStorage.role.code == 'responsable_vente') {

                        $state.go('app.bl.index', {}, { reload: true });
                        //$state.reload('app.clients');
                    } else if($localStorage.role.code == 'fournisseur') {

                        $state.go('app.stockDepot.index', {}, { reload: true });
                        //$state.reload('app.clients');
                    }else{
                        $state.go('app.clients.index', {}, { reload: true });
                    }
                    
                }
            }, function(error) {
                Flash.create('danger', error.data.message);
                $scope.$apply();
            });

    };
}
export default {
    name: 'authController',
    fn: authController
};