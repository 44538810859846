function achatController($translate, $state, $scope, WS, $window, $parse, $compile) {
    'ngInject';
    const vm = this;
    vm.searchbox = {};

    vm.selectFournisseurs = function() {
        WS.get('fournisseurs/selectFournisseursWithCode')
            .then(function(response) {
                if (response.status == 200) {
                    vm.select_frs = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.get =  function (page = 1, per_page = 15) {
        var data = {
            per_page, page,
            // user_code: vm.searchbox.user_code,
            // depot_code: vm.searchbox.depot_code,
             date_debut: vm.searchbox.date_debut,
             date_fin: vm.searchbox.date_fin,
        }
        WS.getData('entete_achat', data)
            .then(function(response) {
                if (response.status == 200) {
                    vm.entetes = response.data.data;
                    vm.current_page = response.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.form;
                    vm.last_page = response.data.last_page;
                    vm.next_page_url = response.data.next_page_url;
                    vm.per_page = Number(response.data.per_page);
                    vm.prev_page_url = response.data.prev_page_url;
                    vm.to = response.data.to;
                    vm.total = response.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }
    $(document).ready(function () {
        $(".sf_promo_gr_debut").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.searchbox.date_debut = date;
                $scope.$apply();
            }
        });

        $(".sf_promo_gr_fin").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.searchbox.date_fin = date;
                $scope.$apply();
            }
        });
    })
    $scope.moveCSV = function(element) {
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.commande.file = event.target.result;
            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.storeEntete = function() {
        if(vm.files){
            vm.commande.file = vm.files[0];
        }else{
            vm.commande.file = null;
        }
        WS.postwithimg('entete_achat/storeEntete', vm.commande, "POST")
            .then(function(response) {
                if (response.data.success) {
                    //vm.entetes.splice(0, 0, response.data.entete); 
                    $scope.$apply();
                    swal(
                        $translate.instant('Succés'),
                        $translate.instant('Commande ajoutée succés'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur d\'insertion!'),
                    'error'
                );
            });
    };

    /**
     * *******************************************new page achat********************************************** 
     */

    vm.auth_chargement = "1";
    vm.chargement_apply_filter = { chargement: vm.auth_chargement };
    vm.applyfilter = function() {
        if (vm.auth_chargement == "0") {
            vm.chargement_apply_filter = '';
        } else {
            vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        }
    };

    vm.getproduits = function() {
        WS.get('mouvements/names')
            .then(function(response) {
                vm.chargement.ch.entete_achat_code = $state.params.code;
                vm.produits = response.data.produits;
                vm.loading = false;
                $scope.$apply();
               vm.getCurrentAchat(); //to get old quantites stored;

            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getCurrentAchat = function(){
        WS.getScache('entete_achat/getCurrentAchat/'+$state.params.code)
            .then(function(response) {
                if(response.data.length > 0){
                    console.log(vm.chargement['ch']['produits']);
                    _.each(vm.chargement['ch']['produits'], (element, index) => {
                        response.data.forEach(ligne => {
                            if(ligne.produit_code == element.produit_code){
                               vm.chargement['ch']['produits'][index]['quantite'] = ligne.quantite;
                               vm.chargement['ch']['produits'][index]['prix_achat_ht'] = ligne.prix_achat_ht;
                               vm.chargement['ch']['produits'][index]['prix_achat_ttc'] = ligne.prix_achat_ttc;
                            }
                        });
                    });
                }
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getGammes = function() {
        WS.get('gammes')
            .then(function(response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getFamilles = function() {
        WS.get('familles')
            .then(function(response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function() {
        WS.get('sousfamilles')
            .then(function(response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.storeLigne_achat = function(chargement) {
        vm.btn_save = false;
        var data = {};
            data.produits = { produits : [] };
        data.entete_achat_code = chargement.ch.entete_achat_code;
        data.colisage = chargement.ch.colisage;
        data.type = chargement.ch.type;
        //console.log(chargement);

        _.each(chargement.ch.produits, (value, key) => {
            if (value.quantite != undefined  && value.quantite != null) {
                data.produits.produits.push(value);
            }
        });
        
        console.log(data);
        
        WS.post('entete_achat/storeLigne', data)
            .then(function(response) {
                swal(
                    $translate.instant('Succès'),
                    $translate.instant('chargement ajoutée avec Succès'),
                    'success'
                ).then(function() {
                    $window.location.reload();
                }).catch(function(error) {
                    $window.location.reload();
                });
                vm.btn_save = true;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
            
    }

    vm.convertirEnBox = function(chargement) {
        if (vm.c_box == true) {
            _.each(chargement.ch.produits, (value, key) => {
                if (value instanceof Object) {
                    vm.chargement['ch']['produits'][key]['quantite'] = Number(vm.chargement['ch']['produits'][key]['quantite']) / Number(value.colisage);
                    
                }

            });
            // cocher colisage
        } else {
            _.each(chargement.ch.produits, (value, key) => {
                if (value instanceof Object) {
                    vm.chargement['ch']['produits'][key]['quantite'] = Number(vm.chargement['ch']['produits'][key]['quantite']) * Number(value.colisage);
                }
            });
            // decocher colisage
        }
        if (vm.c_box == false) { vm.c_box_title = "Convertir en Box"; } else { vm.c_box_title = "Convertir en piéce"; }
        vm.chargement['ch']['colisage'] = vm.c_box;
        vm.c_box = !vm.c_box;


    };

    vm.findEnteteByCode = function(){
         WS.getData('entete_achat/findEnteteByCode', {code : $state.params.code})
            .then(function(response) {
                vm.entete_achat = response.data;
                console.log(vm.entete_achat);
                if(vm.entete_achat.type=="achat"){
                    vm.chargement['ch']['type'] = vm.entete_achat.type;
                }else{
                    vm.chargement['ch']['type'] = '';
                }
                
                $scope.$apply();

            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.clotureEntete_achat = function(code){
        swal({
            title: $translate.instant("Cloture"),
            text: $translate.instant("Valider l'achat de") + " : " + code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function() {

            WS.put('entete_achat/clotureEntete_achat/'+code, {cloture : 1 })
                .then(function(response) {
                    if(response.data.success==true){
                        swal(
                            $translate.instant(`Succé`),
                            $translate.instant(`Achat Ajouté avec succé`),
                            'success'
                        ).then(function() {
                            $window.location.reload();
                        });
                    }
                   
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }).catch(function(){
            console.log('cancelled!');
        });
    };



    vm.getStockDepotByType = function(type){
        WS.getScache('stock_depot/getStockDepotByType/'+type)
        .then(function(response) {
            _.each(vm.chargement.ch.produits, (value, key) => {
                if (value instanceof Object) {
                    _.each(response.data, (s_value, s_key) => {
                        if(s_value.produit_code == value.produit_code){
                            vm.chargement['ch']['produits'][key]['quantite'] = Number(s_value[type]);
                        }
                    });
                }
            });
           $scope.$apply();
        })
        .then(null, function(error) {
            console.log(error);
        });
        
    }
 
    vm.exportAchat = () => {
        WS.getData('entete_achat/exportEnteteAchat', { code : $state.params.code})
        .then(function(response) {
            if(response.data.length>0){
                var filename = response.data[0].type + '-' + response.data[0].code+ '-' + response.data[0].date;
                var file = {
                    sheetid: filename,
                    headers: true,
                    caption: {
                        title: filename,
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'code', title: 'Code' },
                        { columnid: 'date', title: 'Date' },
                        { columnid: 'ru', title: 'Retour Usine' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'cloture', title: 'Cloture' },
                        { columnid: 'user', title: 'Commercial' },
                        { columnid: 'fournisseur', title: 'Fournisseur' },
                        { columnid: 'produit_code', title: 'Code produit' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'prix_achat_ht', title: 'prix achat ht' },
                        { columnid: 'prix_achat_ttc', title: 'prix achat ttc' },
                        { columnid: 'prix_ttc', title: 'prix ttc' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Soufamille' },

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
            }
            
           $scope.$apply();
        })
        .then(null, function(error) {
            console.log(error);
        });
    }
}
export default {
    name: 'achatController',
    fn: achatController
};