function commandesController(DatatableLang, $state, $scope, WS, $window, AppSettings, $translate, $compile, Flash, $http, $uibModal) {
    'ngInject';
    const vm = this;

    vm.tabsFacture = {};
    vm.tabsFacture.bls = [];
    vm.tabsFacture.remiseEspece = 0;
    vm.tabsFacture.ttc = 0;
    vm.tabsFacture.net = 0;

    vm.bls = {};
    vm.center = "35.981789, 10.076292";
    vm.countblock = 1;
    vm.newbl = {};
    vm.newbl.totalRemise = 0;
    vm.newbl.totalTtc = 0;
    vm.newbl.totalNet = 0;
    vm.newbl.gratuites = [];

    vm.keymaps = AppSettings.keymaps;
    vm.per_page = "10";
    vm.currentLang = $translate.proposedLanguage() || $translate.use();
    vm.users = {};
    /**
         * CHECK CONTROLE DE STOCK
         */
    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
                //vm.global_gestion_stock = 1;
                //console.log('vm.global_gestion_stock', vm.global_gestion_stock);
            }, function errorCallback(error) {
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });
    vm.searchByDate = function (commandedate) {
        if (commandedate == undefined || commandedate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (commandedate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (commandedate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            if (commandedate.commercial && commandedate.commercial != "") {
                var url = 'bl/getByDate/' + commandedate.date_debut + '/' + commandedate.date_fin + '/commande/' + commandedate.commercial;
            } else {
                var url = 'bl/getByDate/' + commandedate.date_debut + '/' + commandedate.date_fin + '/commande/null';
            }
            if (commandedate.cloture != '') {
                var url = url + '/' + commandedate.cloture;
            } else {
                var url = url + '/null';
            }
            WS.get(url)
                .then(function (response) {

                    vm.totalremise=0;
                    vm.totalnet=0;
                    vm.commandes = response.data;
                    response.data.forEach(function (e, index) {
                        vm.totalremise += parseFloat(Math.abs(e.remise));
                        vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                    });
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    }
    vm.getCommandescleared = function () {
        WS.get('entetecommercial/top50/commande', vm.access)
            .then(function (response) {
                vm.totalremise=0;
                    vm.totalnet=0;
                    vm.commandes = response.data;
                    response.data.forEach(function (e, index) {
                        vm.totalremise += parseFloat(Math.abs(e.remise));
                        vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                    });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommandes = function () {

        var user_code = $state.params.user_code;
        var date_debut = $state.params.date_debut;
        var date_fin = $state.params.date_fin;

        if (user_code != '' && user_code != undefined) {
            vm.bldate = {};
            vm.bldate.commercial = user_code;
            vm.bldate.date_debut = date_debut;
            vm.bldate.date_fin = date_fin;
            var url = 'entetecommercial/top50/commande/' + user_code + '/' + date_debut + '/' + date_fin;
        } else {
            var url = 'entetecommercial/top50/commande';
        }


        WS.get(url)
            .then(function (response) {
                vm.totalremise=0;
                vm.totalnet=0;
                vm.commandes = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalremise += parseFloat(Math.abs(e.remise));
                    vm.totalnet += parseFloat(Math.abs(e.net_a_payer));
                });
                $scope.$apply();
                $('.table-commandes').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getDetails = function () {
        WS.get('entetecommercial/' + $state.params.id)
            .then(function (response) {
                console.log(response);
                if (response.status == 200) {
                    vm.detailss = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getDetailsbyCode = function (code) {
        var url = 'entetecommercial/code/' + $state.params.id;
        WS.get(url)
            .then(function (response) {
                console.log(response);
                vm.details = response.data;

                /**
    * Maps
    */
                $('#mapsDetailsCMD').empty();
                $('#mapsDetailsCMD').append('<div style="width: 100%; height: 580px" id="mapContainerDetailsCMD"></div>');

                var mymap = L.map('mapContainerDetailsCMD').setView([vm.details.latitude, vm.details.longitude], 15);

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                }).addTo(mymap);

                // Creates a red marker with the coffee icon
                var redMarkerClient = L.ExtraMarkers.icon({
                    icon: 'fa-home',
                    markerColor: 'blue',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerClient = L.marker([vm.details.client.latitude, vm.details.client.longitude], { icon: redMarkerClient }).addTo(mymap);
                var infoClient = $compile(`
                                         <div id="bodyContent" style="width=400px;">
                                              <div class="text-center">
                                                <a ui-sref="app.clients.historiques({code : '${vm.details.client_code}'})" target="_blank">
                                                  Historique
                                                </a>
                                              </div>
                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                              <div><b>Client</b> : ${vm.details.client.nom} ${vm.details.client.prenom}</a></div>
                                              <div><b>Livreur</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                              <div><b>Montant</b> : ${vm.details.net_a_payer}</div>
                                            </div>
                                        `)($scope);
                var popupClient = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoClient[0])
                markerClient.bindPopup(popupClient);

                var redMarkerVisite = L.ExtraMarkers.icon({
                    icon: 'fa-eye',
                    markerColor: 'red',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerVisite = L.marker([vm.details.latitude, vm.details.longitude], { icon: redMarkerVisite }).addTo(mymap);
                var infoVisite = $compile(`
                                         <div id="bodyContent" style="width=400px;">
                                              <div class="text-center">
                                                Visite
                                              </div>
                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                              <div><b>Livreur</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                              <div><b>Montant</b> : ${vm.details.net_a_payer}</div>
                                            </div>
                                        `)($scope);
                var popupVisite = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoVisite[0])
                markerVisite.bindPopup(popupVisite);

                setTimeout(function () {
                    mymap.invalidateSize();
                }, 80);

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.exportExcel = function (commandedate) {
        //entetecommercial
        if (commandedate == undefined || commandedate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (commandedate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (commandedate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/' + commandedate.date_debut + '/' + commandedate.date_fin + '/commande/null'
            if (commandedate.commercial && commandedate.commercial != "") {
                var url = url + '/' + commandedate.commercial;
            }
            WS.get(url)
                .then(function (response) {
                    //console.log(response.data);
                    var filename = 'Commande-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };
    var stylecsv = {
        sheetid: 'Commande - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Commande - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'code', title: 'Code produit' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'marque', title: 'Marque' },
            { columnid: 'gamme', title: 'Gamme' },
            { columnid: 'famille', title: 'Famille' },
            { columnid: 'sousfamille', title: 'Sous_Famille' },
            { columnid: 'entetecommercial_code', title: 'Commande_N°' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'pu_ht', title: 'PU_HT' },
            { columnid: 'pu_ttc', title: 'PU_TTC' },
            { columnid: 'p_tva', title: 'p_tva' },
            { columnid: 'taux_tva', title: 'TVA' },
            { columnid: 'total', title: 'Total' },
            { columnid: 'date_creation', title: 'DATE' },
            { columnid: 'remise', title: 'Remise' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'routing_code', title: 'Route' },
            { columnid: 'localite', title: 'Localite' },
            { columnid: 'region', title: 'Region' },
            { columnid: 'gouvernorat', title: 'Gouvernorat' },
            { columnid: 'secteur', title: 'Secteur' },
            { columnid: 'zone', title: 'Zone' }
        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    /*
      '*********** 
      ' Devise=0   aucune 
      '       =1   Dinar  
      '       =2   Dollar $ 
      ' Langue=0   Français 
      '       =1   Belgique 
      '       =2   Suisse 
      '*********** 
      ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99 
      ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales 
      */
    vm.ConvNumberLetter_fr = function (Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();

        if (isNaN(parseFloat(Nombre))) return "";

        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0);
            }
            return strLetter + 'Millimes';
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = " virgule";
                break;
            case 1:
                strDev = " Dinar";
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = " Dollar";
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }
        if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";
        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = "mille ";
                break;
            default:
                StrTmp = StrTmp + " mille ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " million ";
                break;
            default:
                StrTmp = StrTmp + " millions ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " milliard ";
                break;
            default:
                StrTmp = StrTmp + " milliards ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " billion ";
                break;
            default:
                StrTmp = StrTmp + " billions ";
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept",
            "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze",
            "seize", "dix-sept", "dix-huit", "dix-neuf");
        TabDiz = Array("", "", "vingt", "trente", "quarante", "cinquante",
            "soixante", "soixante", "quatre-vingt", "quatre-vingt");
        if (Langue == 1) {
            TabDiz[7] = "septante";
            TabDiz[9] = "nonante";
        } else if (Langue == 2) {
            TabDiz[7] = "septante";
            TabDiz[8] = "huitante";
            TabDiz[9] = "nonante";
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = " et ";
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }

    // vm.deleteEntete = function (code) {
    //     swal({
    //         title: $translate.instant(`Suppression`),
    //         text: $translate.instant(`Code`) + ` : ${code}`,
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: $translate.instant('Oui'),
    //         cancelButtonText: $translate.instant('Non'),
    //         confirmButtonClass: 'btn btn-success',
    //         cancelButtonClass: 'btn btn-danger',
    //         buttonsStyling: true
    //     }).then(function () {

    //         WS.delete('entetecommercial/deleteCommande/' + code)
    //             .then(function (response) {
    //                 if (response.data.success == true) {
    //                     swal(
    //                         $translate.instant('Succès'),
    //                         $translate.instant('Document supprimée avec Succès'),
    //                         'success'
    //                     ).then(function () {
    //                         $window.location.reload();
    //                     });
    //                 } else {
    //                     swal(
    //                         $translate.instant('Attention'),
    //                         $translate.instant('Cette Commande correspond a la bon de livraison N°:') + response.data.bl.code,
    //                         'warning'
    //                     );
    //                 }

    //             })
    //             .then(null, function (error) {
    //                 console.log(error);
    //             });
    //     });

    // };
    vm.deleteBl_all = function (code, user) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
           // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer le bl ainsi que leurs mouvements, visites et paiements')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = code;

                    $scope.deleteBl = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password,
                                type : 'commande'
                            };
                            //console.log(data);
                            WS.post('entetecommercial/deleteFromDash', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };
    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix");

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    };

    vm.waveSoft = function (bldate) {
        if (bldate == undefined || bldate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            var url = 'bl/getByDateExport/wavesoft/' + bldate.date_debut + '/' + bldate.date_fin + '/commande';
            $window.location.href = AppSettings.apiUrl + url;
        }
    };

    vm.clotureCommande = function (cmd) {
        /**
         * cmd = {
         *      code : code,
         *      envoi : envoi
         * }
         */
        swal({
            title: 'Voulez-vous Transformer la commande',
            text: $translate.instant(`N° : ${cmd.code} en bon de livraison`),
            //text: $translate.instant(`Voulez-vous vraiment Transformer cette commande en bon de livraison`) + ' : ' + cmd.code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {

            WS.post('commandes/clotureCommande', cmd).then(
                (response) => {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Cloture'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }).catch(function (error) {
            //console.log(error);
        });
    };


    vm.annulerCMD = function (code_cmd) {
        swal({
            title: '',
            html: '<h3>'+$translate.instant(`Voulez-vous vraiment Annuler cette commande`) + ' : </h3><h4>' + code_cmd+ ' </h4>' ,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {

            WS.post('commandes/annulerCMD', { code: code_cmd }).then(
                (response) => {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Annulé'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }).catch(function (error) {
            //console.log(error);
        });
    };


    vm.storeCommande = function () {

        if (vm.newbl.distributeur_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un distributeur"));
            return;
        }
        if (vm.newbl.depot_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un depot"));
            return;
        }
        if (vm.newbl.client_code == undefined) {
            Flash.create('danger', $translate.instant("Veuillez choisir un client"));
            return;
        }
        angular.forEach(vm.newbl.lignes, function (value, key) {
            if (value.produit_code == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la liste des produits"));
                return;
            }
            if (value.quantite == undefined) {
                Flash.create('danger', $translate.instant("Veuillez bien remplir la quantite dans la liste des produits"));
                return;
            }
        });

        // if ((!angular.isUndefined(vm.newbl.cheque) && vm.newbl.cheque != "") || (!angular.isUndefined(vm.newbl.num_cheque) && vm.newbl.num_cheque != "")) {
        //     if (typeof vm.newbl.banque_cheque == "undefined" || vm.newbl.banque_cheque == "" || typeof vm.newbl.date_echeance_cheque == "undefined" || vm.newbl.date_echeance_cheque == "") {
        //         Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du cheque"));
        //         return;
        //     }
        // }
        // if ((!angular.isUndefined(vm.newbl.banque_cheque) && vm.newbl.banque_cheque != "") || (!angular.isUndefined(vm.newbl.date_echeance_cheque) && vm.newbl.date_echeance_cheque != "")) {
        //     if (typeof vm.newbl.cheque == "undefined" || vm.newbl.cheque == "" || typeof vm.newbl.num_cheque == "undefined" || vm.newbl.num_cheque == "") {
        //         Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du cheque"));
        //         return;
        //     }
        // }
        // if ((!angular.isUndefined(vm.newbl.traite) && vm.newbl.traite != "") || (!angular.isUndefined(vm.newbl.num_traite) && vm.newbl.num_traite != "")) {
        //     if (typeof vm.newbl.banque_traite == "undefined" || vm.newbl.banque_traite == "" || typeof vm.newbl.date_echeance_traite == "undefined" || vm.newbl.date_echeance_traite == "") {
        //         Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du traite"));
        //         return;
        //     }
        // }
        // if ((!angular.isUndefined(vm.newbl.banque_traite) && vm.newbl.banque_traite != "") || (!angular.isUndefined(vm.newbl.date_echeance_traite) && vm.newbl.date_echeance_traite != "")) {
        //     if (typeof vm.newbl.traite == "undefined" || vm.newbl.traite == "" || typeof vm.newbl.num_traite == "undefined" || vm.newbl.num_traite == "") {
        //         Flash.create('danger', $translate.instant("Veuillez bien remplir les informations du traite"));
        //         return;
        //     }
        // }
        if (vm.global_gestion_stock == 1) {
            var authorize_call = 0;
            _.each(vm.newbl.lignes, (element, key) => {
                if (element.stock < element.quantite || element.quantite <= 0) {
                    authorize_call++;
                }
            });
            if (authorize_call == 0) {
                WS.post('entetecommercial/storeCommande/commande', vm.newbl).then(
                    function (response) {
                        vm.newbl = {};
                        vm.newbl.lignes = {};
                        vm.newbl.totalRemise = 0;
                        vm.newbl.totalTtc = 0;
                        vm.newbl.totalNet = 0;
                        Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                        //vm.bls.unshift(response.data);
                        $('#block-0').nextAll().remove();
                        $state.go("app.commandes.details", { id: response.data.code });
                        $('.modal-backdrop').remove();
                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives'),
                    'warning'
                )
            }
        } else {
            //console.log(vm.newbl);
            WS.post('entetecommercial/storeCommande/commande', vm.newbl).then(
                function(response) {
                    vm.newbl = {};
                    vm.newbl.lignes = {};
                    vm.newbl.totalRemise = 0;
                    vm.newbl.totalTtc = 0;
                    vm.newbl.totalNet = 0;
                    Flash.create('success', $translate.instant("Bl ajoutée avec succée"));
                    //vm.bls.unshift(response.data);
                    $('#block-0').nextAll().remove();
                    $state.go("app.commandes.details", { id: response.data.code });
                    $('.modal-backdrop').remove();
                    $scope.$apply();
                },
                function(error) {
                    console.log(error);
                });
        }

    };

    vm.addBlock = function (index) {
        var newBlock = $compile(`<div id="block-` + index + `">
                            <div class="col-md-5">
                                <div class="form-group">
                                  <div ui-select name="produit-` + index + `" ng-model="vm.newbl.lignes[` + index + `].produit_code" theme="bootstrap"
                                      ng-change="vm.getPuProduct(` + index + `, vm.newbl.lignes[` + index + `].produit_code);
                                                vm.checkGratuiter(` + index + `, vm.newbl.lignes[` + index + `].produit_code, vm.newbl.lignes[` + index + `].quantite);" >
                                    <ui-select-match placeholder="Produit...">{{$select.selected.libelle}}</ui-select-match>
                                    <ui-select-choices repeat="item.code as item in vm.produits | filter: $select.search">
                                      <div ng-bind-html="item.libelle | highlight: $select.search"></div>
                                    </ui-select-choices>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-1 text-center"  ng-if="vm.global_gestion_stock == 1">
                                <input type="text" disabled class="form-control" ng-model="vm.newbl.lignes[` + index + `].stock">
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].quantite" required
                                        ng-class="{ 'red_quantite' : (vm.newbl.lignes[` + index + `].stock < vm.newbl.lignes[` + index + `].quantite || vm.newbl.lignes[` + index + `].quantite<=0) && vm.global_gestion_stock == 1 }"
                                        ng-keyup="vm.checkGratuiter(` + index + `, vm.newbl.lignes[` + index + `].produit_code, vm.newbl.lignes[` + index + `].quantite);">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].prix_ttc" required
                                           ng-keyup="vm.recalculateLigne(` + index + `)">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].remise" required
                                           ng-keyup="vm.recalculateLigne(` + index + `)">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].ttc" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newbl.lignes[` + index + `].net" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <i class="fa fa-minus iconred" ng-click="vm.removeBlock(` + index + `)"></i>
                            </div>
                            <div class="col-md-12" id="block-grat-` + index + `"></div>
                        </div>`)($scope);
        vm.countblock = index + 1;
        $('#panelProduits').append(newBlock);
    };

    vm.removeBlock = function (index) {
        if (vm.newbl.lignes != undefined && vm.newbl.lignes[index]) {
            var mod = _.omit($parse("vm.newbl.lignes")($scope), index);
            $parse("vm.newbl.lignes").assign($scope, mod);
            //remove gratuiter
            var mod = _.omit($parse("vm.newbl.gratuites")($scope), index);
            $parse("vm.newbl.gratuites").assign($scope, mod);
            vm.newbl.gratuites = $.map(vm.newbl.gratuites, function (value, index) {
                return [value];
            });
            // calcul total
            vm.newbl.totalRemise = 0;
            vm.newbl.totalTtc = 0;
            vm.newbl.totalNet = 0;
            angular.forEach(vm.newbl.lignes, function (value, key) {
                vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.ttc);
                vm.newbl.totalNet = roundToThree(vm.newbl.totalNet + value.net);
            });
            vm.newbl.totalRemise = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNet);
        }
        $('#block-' + index).remove();
    };

    // vm.getPuProduct = function (index, produit_code) {
    //     WS.get('produits/getPuProduct/' + produit_code +'/' + vm.newbl.client_code).then(
    //         function (response) {
    //             vm.newbl.lignes[index].prix_ttc = response.data.prix_ttc;
    //             vm.newbl.lignes[index].prix_ht = response.data.prix_ht;
    //             vm.newbl.lignes[index].stock = response.data.stock;
    //             $scope.$apply();
    //         },
    //         function (error) {
    //             console.log(error);
    //         });
    // };


    vm.getPuProduct = function (index, produit_code) {
        WS.getData('produits/getPuProduct', {
            produit_code: produit_code,
            client_code: vm.newbl.client_code,
            depot_code: vm.newbl.depot_code,
        })
            .then(
                function (response) {
                    vm.newbl.lignes[index].prix_ttc = response.data.prix_ttc;
                    vm.newbl.lignes[index].prix_ht = response.data.prix_ht;
                    vm.newbl.lignes[index].stock = response.data.stock;
                    vm.newbl.lignes[index].stock_carton = response.data.stock / response.data.colisage;
                    vm.newbl.lignes[index].stock_carton = vm.newbl.lignes[index].stock_carton.toFixed(2);
                    vm.newbl.lignes[index].colisage = response.data.colisage;
                    vm.newbl.lignes[index].tva = response.data.tva;
                    vm.newbl.lignes[index].prix_achat_ht = response.data.prix_achat_ht;
                    vm.newbl.lignes[index].prix_achat_ttc = response.data.prix_achat_ttc;
                    vm.newbl.lignes[index].quota = response.data.quota;
                    //console.log('before', vm.produits);

                    vm.renewListProd();

                    $scope.$apply();

                },
                function (error) {
                    console.log(error);
                });
    };

    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };

    vm.selectDepots = function () {
        WS.get('depots/selectDepot').then(
            function (response) {
                vm.depots = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectClientsWithCode = function () {
        WS.get('clients/selectClientsWithCode').then(
            function (response) {
                vm.clients = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectProduitsWithCode = function () {
        WS.get('produits/selectProduitsWithCode').then(
            function (response) {
                vm.produits = response.data;
            },
            function (error) {
                console.log(error);
            });
    };

    vm.recalculateLigne = (index) => {
        //vm.newbl.lignes[index].remise = response.data.remise;
        vm.newbl.lignes[index].ttc = (vm.newbl.lignes[index].quantite) ? roundToThree(vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ttc) : 0;
        vm.newbl.lignes[index].net = roundToThree(vm.newbl.lignes[index].ttc - ((vm.newbl.lignes[index].remise * vm.newbl.lignes[index].ttc) / 100));
        // calcul total
        vm.newbl.totalRemise = 0;
        vm.newbl.totalTtc = 0;
        vm.newbl.totalNet = 0;
        angular.forEach(vm.newbl.lignes, function (value, key) {
            vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.ttc);
            vm.newbl.totalNet = roundToThree(vm.newbl.totalNet + value.net);
        });
        vm.newbl.totalRemise = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNet);
    }

    vm.checkGratuiter = function (index, produit_code, quantite) {
        if (quantite > 0) {
            var url = 'produits/checkRemise/' + produit_code + '/' + quantite;
        } else {
            var url = 'produits/checkRemise/' + produit_code + '/' + 0;
        }
        WS.get(url).then(
            function (response) {
                vm.newbl.lignes[index].remise = response.data.remise;
                vm.newbl.lignes[index].ttc = (vm.newbl.lignes[index].quantite) ? roundToThree(vm.newbl.lignes[index].quantite * vm.newbl.lignes[index].prix_ttc) : 0;
                vm.newbl.lignes[index].net = roundToThree(vm.newbl.lignes[index].ttc - ((vm.newbl.lignes[index].remise * vm.newbl.lignes[index].ttc) / 100));
                // calcul total
                vm.newbl.totalRemise = 0;
                vm.newbl.totalTtc = 0;
                vm.newbl.totalNet = 0;
                angular.forEach(vm.newbl.lignes, function (value, key) {
                    vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.ttc);
                    vm.newbl.totalNet = roundToThree(vm.newbl.totalNet + value.net);
                });
                vm.newbl.totalRemise = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNet);

                $scope.$apply();

                if (quantite > 0) {
                    var url = 'produits/checkGratuiter/' + produit_code + '/' + quantite + '/' + vm.newbl.lignes[index].net;
                } else {
                    var url = 'produits/checkGratuiter/' + produit_code + '/' + 0 + '/' + vm.newbl.lignes[index].net;
                }

                WS.get(url).then(
                    function (response) {
                        if (vm.newbl.gratuites && vm.newbl.gratuites.length > 0 && vm.newbl.gratuites[index]) {
                            var mod = _.omit($parse("vm.newbl.gratuites")($scope), index);
                            $parse("vm.newbl.gratuites").assign($scope, mod);
                            vm.newbl.gratuites = $.map(vm.newbl.gratuites, function (value, index) {
                                return [value];
                            });
                        }
                        if (response.data.length > 0) {
                            var alert = `<div class="alert alert-success" role="alert">
                                  ` + $translate.instant('Produit(s) gratuit(s)') + ` : <br/>`;
                            vm.newbl.gratuites[index] = response.data;
                            response.data.forEach(function (element, ind) {
                                alert += element.quantite + ` - ` + element.produit_code + ` ` + element.gratuit_libelle;
                            });
                            alert += `</div>`;
                            $('#block-grat-' + index).empty();
                            $('#block-grat-' + index).append(alert);
                        } else {
                            $('#block-grat-' + index).empty();
                        }
                        //console.log(vm.newbl.gratuites);
                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            },
            function (error) {
                console.log(error);
            });
    };

    vm.exportbl = function (code) {

        var url = 'bl/getExportBl/' + code;

        WS.get(url)
            .then(function (response) {
                var date_debut = response.data[response.data.length - 1].date_creation;
                var date_fin = response.data[0].date_creation;
                var filename = 'Bl-' + code;
                var stylecsv = {
                    sheetid: filename,
                    headers: true,
                    caption: {
                        title: filename,
                        style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                    },
                    //style:'background:#fff',
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Sous_Famille' },
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'remise_commande', title: 'Remise escompte' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'prix_achat_ttc', title: 'prix_achat' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code_erp_client', title: 'code_erp_client' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'matricule_fiscale', title: 'Matricule fiscale' },
                        { columnid: 'rc', title: 'RC' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'localite', title: 'Localite' },
                        { columnid: 'region', title: 'Region' },
                        { columnid: 'gouvernorat', title: 'Gouvernorat' },
                        { columnid: 'secteur', title: 'Secteur' },
                        { columnid: 'zone', title: 'Zone' }
                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error)=>{

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    vm.updateModeEnvoie = () => {
        if(vm.cmd.with_timbre){
            vm.cmd.envoi = "non";
        }
    }
}
export default {
    name: 'commandesController',
    fn: commandesController
};