function planDeChargeController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
    vm.plans = {};
    vm.filter = {};
    vm.filter.date_debut = '';
    vm.filter.date_fin = '';
    vm.filter.commercial = '';

    vm.listProds = {};
    vm.getPlans = function (libelle) {
        WS.getData('plandecharges/web/' + libelle, vm.acces)
            .then(function (response) {
                if (response.status == 200) {
                    vm.plans = response.data;
                    $scope.$apply();

                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectProduits = function () {
        WS.get('produits/selectProduitGamme')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                vm.selectgammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousFamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getcategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categoriescommercials = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getActivites = function () {
        WS.get('activites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;

                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }




    vm.tabs_cats = [];
    vm.res_tabs_cats = [];

    vm.selectCategoriesWithActivites = () => {

        WS.get('plandecharges/selectCategoriesWithActivites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.categories = response.data;
                    vm.max_activites = 0;
                    _.each(vm.categories, element => {
                        var nbr_act = element.activites.length;
                        if (nbr_act > vm.max_activites) {
                            vm.max_activites = nbr_act;
                        }

                        vm.tabs_cats[element.code] = false;
                        _.each(element.activites, activite => {
                            vm.tabs_cats[element.code + '_' + activite.code] = false;
                        })
                    })
                    $scope.$apply();


                    // $('.button-checkbox-roles').each(function() {

                    //     // Settings
                    //     var $widget = $(this),
                    //         $button = $widget.find('button'),
                    //         $checkbox = $widget.find('input:checkbox'),
                    //         color = $button.data('color'),
                    //         settings = {
                    //             on: {
                    //                 icon: 'glyphicon glyphicon-check'
                    //             },
                    //             off: {
                    //                 icon: 'glyphicon glyphicon-unchecked'
                    //             }
                    //         };

                    //     // Event Handlers
                    //     // $button.on('click', function(event) {
                    //     //     $checkbox.prop('checked', !$checkbox.is(':checked'));
                    //     //     $checkbox.triggerHandler('change');

                    //     //     const id = $(this).attr('id');
                    //     //     updateDisplay(id);
                    //     // });
                    //     // $checkbox.on('change', function() {
                    //     //     const id = $(this).attr('id');
                    //     //     updateDisplay(id);
                    //     // });

                    //     // Actions
                    //     function updateDisplay(id) {
                    //         var isChecked = $checkbox.is(':checked');
                    //         if (id != undefined) {
                    //             if (isChecked) {
                    //                 vm.tabs_cats[id] = true;
                    //             } else {
                    //                 vm.tabs_cats[id] = false;
                    //             }
                    //             //console.log(vm.auths[id]);
                    //             //console.log(vm.auths);
                    //         }
                    //         // Set the button's state
                    //         $button.data('state', (isChecked) ? "on" : "off");

                    //         // Set the button's icon
                    //         $button.find('.state-icon')
                    //             .removeClass()
                    //             .addClass('state-icon ' + settings[$button.data('state')].icon);

                    //         // Update the button's color
                    //         if (isChecked) {
                    //             $button
                    //                 .removeClass('btn-default')
                    //                 .addClass('btn-' + color + ' active');
                    //         } else {
                    //             $button
                    //                 .removeClass('btn-' + color + ' active')
                    //                 .addClass('btn-default');
                    //         }
                    //     }


                    //     // Initialization
                    //     function init() {

                    //         updateDisplay();

                    //         // Inject the icon if applicable
                    //         if ($button.find('.state-icon').length == 0) {
                    //             $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                    //         }
                    //     }
                    //     init();
                    // });

                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.manage = (categorie_code, activite_code = null, type, activites) => {
        console.log(categorie_code, activite_code);
        if (type == 'categorie') {
            vm.tabs_cats[categorie_code] = !vm.tabs_cats[categorie_code];
            if (vm.tabs_cats[categorie_code]) {
                if (activites.length > 0) {
                    _.each(activites, element => {
                        var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == element.code);
                        if (index == -1) {
                            vm.res_tabs_cats.push({
                                categorie_code: categorie_code,
                                activite_code: element.code
                            })
                            vm.tabs_cats[categorie_code + '_' + element.code] = true;
                            $(`#${categorie_code}_${element.code}>i`).removeClass('glyphicon-unchecked').addClass('glyphicon-check')

                        }
                    })
                    $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked');
                } else {
                    var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == null);
                    if (index == -1) {
                        vm.res_tabs_cats.push({
                            categorie_code: categorie_code,
                            activite_code: null
                        })
                        vm.tabs_cats[categorie_code] = true;
                        $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                    }
                }
            } else {
                if (activites.length > 0) {
                    _.each(activites, element => {
                        var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == element.code);
                        if (index > -1) {
                            vm.res_tabs_cats.splice(index, 1);
                            vm.tabs_cats[categorie_code + '_' + element.code] = false;
                            $(`#${categorie_code}_${element.code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                        }
                    })
                    $(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked');

                } else {
                    var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == null);
                    if (index > -1) {
                        vm.res_tabs_cats.splice(index, 1);
                        vm.tabs_cats[categorie_code] = false;
                        $(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                    }
                }
            }



        } else if (type == 'activite') {
            vm.tabs_cats[categorie_code + '_' + activite_code] = !vm.tabs_cats[categorie_code + '_' + activite_code];

            if (vm.tabs_cats[categorie_code + '_' + activite_code]) {
                var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == activite_code);
                console.log(index)
                if (index == -1) {
                    vm.res_tabs_cats.push({
                        categorie_code: categorie_code,
                        activite_code: activite_code
                    })
                    vm.tabs_cats[categorie_code] = true;
                    $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                    $(`#${categorie_code}_${activite_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                }
            } else {
                var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == activite_code);
                //console.log(index)
                if (index > -1) {
                    vm.res_tabs_cats.splice(index, 1);
                    //vm.tabs_cats[categorie_code] =false;
                    //$(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                    $(`#${categorie_code}_${activite_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                }
            }

        }
        console.log('clicked_cats', vm.res_tabs_cats)

    }

    vm.addPlanDeCharge = () => {
        //console.log(vm.listProds);
        var prods = [];
        _.each(vm.listProds, (element, produit_code) => {
            if (element) {
                prods.push(produit_code);
            }
        })
        if (prods.length == 0) {
            swal('Oups!', 'Veuillez bien selectionner au moins un produit!', 'warning');
        } else if (vm.libelle == '') {
            swal('Oups!', 'Veuillez bien remplir le champ libelle', 'warning');
        } else if (vm.date_debut == '') {
            swal('Oups!', 'Veuillez bien remplir le champ date début', 'warning');
        } else if (vm.date_fin == '') {
            swal('Oups!', 'Veuillez bien remplir le champ date fin', 'warning');
        }
        else if (vm.res_tabs_cats.length == 0) {
            swal('Oups!', 'Veuillez bien selectionner au moins une catégorie', 'warning');
        } else {
            const data = {
                libelle: vm.libelle,
                date_debut: vm.date_debut,
                date_fin: vm.date_fin,
                prods: prods,
                categories: vm.res_tabs_cats
            };

            console.log(data);


            WS.post('plandecharges/add', data)
                .then(function (response) {
                    if (response.status == 200) {
                        swal(
                            $translate.instant('Succé'),
                            $translate.instant('Plan de charge ajoutée avec succées'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });

        }

    }


    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportPlans = function (libelle) {

        var url = 'plandecharges/web/' + libelle;

        WS.get(url)
            .then(function (response) {

                var filename = 'Plan de charges';
                var stylecsv = {
                    sheetid: filename,
                    headers: true,
                    caption: {
                        title: filename,
                        style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                    },

                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'libelle', title: 'Libelle' },
                        { columnid: 'date_debut', title: 'date_debut' },
                        { columnid: 'date_fin', title: 'date_fin' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'activite', title: 'activite' },
                        { columnid: 'categorie', title: 'categorie' }
                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getVisitesInit = function () {


        var url = 'plandecharges/top100';
        WS.getScache(url)
            .then(function (response) {
                vm.totalvisite = 0;
                vm.totalpres = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalvisite += 1;
                    vm.totalpres += parseFloat(Math.abs(e.total_presente));
                });
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getVisites = function (bldate) {

        if (bldate.commercial && bldate.commercial != "") {
            var url = 'plandecharges/top100/' + bldate.date_debut + '/' + bldate.date_fin + '/' + bldate.commercial;
        } else {
            var url = 'plandecharges/top100/' + bldate.date_debut + '/' + bldate.date_fin;
        }

        WS.getScache(url)
            .then(function (response) {
                vm.totalvisite = 0;
                vm.totalpres = 0;
                vm.bls = response.data;
                response.data.forEach(function (e, index) {
                    vm.totalvisite += 1;
                    vm.totalpres += parseFloat(Math.abs(e.total_presente));
                });
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDetailsbyCode = function (code) {
        var url = 'plandecharges/code/' + $state.params.id;
        WS.get(url)
            .then(function (response) {
                vm.details = response.data;


                $scope.$apply();

                $('#mapsDetailsBl').empty();
                $('#mapsDetailsBl').append('<div style="width: 100%; height: 580px" id="mapContainerDetailsBl"></div>');

                var mymap = L.map('mapContainerDetailsBl').setView([vm.details.latitude, vm.details.longitude], 17);


                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                })
                    .addTo(mymap);

                var redMarkerClient = L.ExtraMarkers.icon({
                    icon: 'fa-home',
                    markerColor: 'blue',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerClient = L.marker([vm.details.client.latitude, vm.details.client.longitude], { icon: redMarkerClient }).addTo(mymap);
                var infoClient = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${vm.details.client_code}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                                              <div><b>Client</b> : ${vm.details.client.nom} ${vm.details.client.prenom}</a></div>
                                                              <div><b>Delegue</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                                             
                                                            </div>
                                                        `)($scope);
                var popupClient = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoClient[0])
                markerClient.bindPopup(popupClient);

                var redMarkerVisite = L.ExtraMarkers.icon({
                    icon: 'fa-eye',
                    markerColor: 'red',
                    shape: 'circle', //'circle', 'square', 'star', or 'penta'
                    prefix: 'fa'
                });
                var markerVisite = L.marker([vm.details.latitude, vm.details.longitude], { icon: redMarkerVisite }).addTo(mymap);
                var infoVisite = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                ${$translate.instant('Visite')}
                                                              </div>
                                                              <div><b>BL N°</b> : ${vm.details.code}</a></div>
                                                              <div><b>Delegue</b> : ${vm.details.commercial.nom} ${vm.details.commercial.prenom}</a></div>
                                                             </div>
                                                        `)($scope);
                var popupVisite = L.popup({
                    maxWidth: 600,
                    minWidth: 300
                }).setContent(infoVisite[0])
                markerVisite.bindPopup(popupVisite);

                setTimeout(function () {
                    mymap.invalidateSize();
                }, 80);
            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    vm.update = function (plan) {

        WS.put('plandecharges/' + plan.id, plan)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succés'),
                        $translate.instant('plan modifiée avec succés'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.delete = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer ce plan!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('plandecharges/' + id).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('plan Supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer ce plan'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }

    vm.ajouter = function (plan) {
        WS.post('plandecharges', plan)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('plan ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
}
export default {
    name: 'planDeChargeController',
    fn: planDeChargeController
};