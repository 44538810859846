const AppSettings = {
    appTitle: 'Nomadis',
    mapsalgerie: '34.442277, 3.492232',
    zoomalgerie : 7,
     	//keymaps :'AIzaSyAMZBIUbxC0z-je7wnaB0Jk5J3FSDT-Vzs',
	keymaps :'AIzaSyD4LczsNyGOhObVIx-mooEO5fhKVc45jIE',
     	//apiUrl: 'https://api.nomadis.tantoushgroup.online/api/v1/',
	// apiUrl:'https://api.lagoulette.nomadis.online/api/v1/',
	// apiUrl:'https://api.3p.nomadis.online/api/v1/',
	//apiUrl:'https://api.sancella.clediss.ovh/api/v1/',
	//apiUrl: 'https://api.algerie.clediss.com/api/v1/',
	// apiUrl: 'https://api.distrisam.nomadis.online/api/v1/',
	// apiUrl: 'https://api.distrisam3.nomadis.online/api/v1/',
	//apiUrl: 'https://api.nomadismostaganem.nomadis.online/api/v1/',
	// apiUrl: 'https://api.nomadistiaret.nomadis.online/api/v1/',
	// apiUrl: 'https://api.rimofood.clediss.online/api/v1/',
	// apiUrl: 'https://api.triplecare.clediss.online/api/v1/',
	//apiUrl: 'https://api.joys.nomadis.online/api/v1/',
	//apiUrl: 'https://api.mondial.nomadis.online/api/v1/',
	//apiUrl: 'https://api.clediss.nomadis.online/api/v1/',
	// apiUrl:'https://api.3p.nomadis.online/api/v1/',
//SANCELLA
	// apiUrl: 'https://api.nomadis.sancella.online/api/v1/',
	// apiUrl: 'https://api.fubus.sancella.online/api/v1/',
	// apiUrl: 'https://api.canal-food.sancella.online/api/v1/',
	// apiUrl: 'https://api.sidibouzid.sancella.online/api/v1/',
	// apiUrl: 'https://api.medenine.sancella.online/api/v1/',

	//NATILAIT
	//apiUrl: 'https://api.sousse.natilait.online/api/v1/',
	//apiUrl: 'https://api.mahdia.natilait.online/api/v1/',
 	//apiUrl: 'https://api.sfax.natilait.online/api/v1/',
      	//apiUrl: 'https://api.sfax2.natilait.online/api/v1/',
	//apiUrl: 'https://api.marsa.natilait.online/api/v1/',
 	apiUrl: 'https://api.bnikhalled.natilait.online/api/v1/',
	//apiUrl: 'https://api.gasrine.natilait.online/api/v1/',
	//apiUrl: 'https://api.bousalem.natilait.online/api/v1/',
	//apiUrl: 'https://api.elkef.natilait.online/api/v1/',
	//apiUrl: 'https://api.gabes.natilait.online/api/v1/',
 	//apiUrl: 'https://api.utique.natilait.online/api/v1/',
	//apiUrl: 'https://api.test.natilait.online/api/v1/',
	//apiUrl: 'https://api.kairouan.natilait.online/api/v1/',
	//apiUrl: 'https://api.bizerte.natilait.online/api/v1/',
	//apiUrl: 'https://api.medenine.natilait.online/api/v1/',
	// apiUrl: 'https://api.metlaoui.natilait.online/api/v1/',
	// apiUrl: 'https://api.slimen.natilait.online/api/v1/',
	// apiUrl: 'https://api.sidibouzid.natilait.online/api/v1/',
herewego : {
                app_id : 'VxpPmyKjSh1FcbqCq7d7',
                app_code : 'sjYx8madd1LAp_IP0Qo1GQ'
        }
        ,
        //using mapbox accesstocken
	mapbox : {
		access_token : 'pk.eyJ1IjoiY2xlZGlzc25vbWFkaXMiLCJhIjoiY2p0b2w1MHJqMDU2czQ1cG5tY3BubmV2ZiJ9.Rj7DwA-miYdEMSKsFG0amg'
	}
,
	"lang": "fr_FR"
	//"lang": "ar_AR"
};
export default AppSettings;

